
export const meditationsBySyllable = [
   [], // we plug in number of remaining syllables, so adjusting for zero index
   [
      "from",
      "my",
      "i",
      "learned",
      "good",
      "and",
      "of",
      "a",
      "not",
      "deeds",
      "but",
      "thoughts",
      "in",
      "way",
      "far",
      "rich",
      "to",
      "have",
      "schools",
      "had",
      "at",
      "home",
      "know",
      "that",
      "on",
      "such",
      "things",
      "man",
      "should",
      "spend",
      "green",
      "nor",
      "blue",
      "or",
      "fights",
      "him",
      "too",
      "want",
      "work",
      "with",
      "own",
      "hands",
      "give",
      "what",
      "was",
      "said",
      "by",
      "breed",
      "for",
      "up",
      "speech",
      "been",
      "first",
      "then",
      "youth",
      "plank",
      "skin",
      "else",
      "kind",
      "off",
      "as",
      "who",
      "much",
      "does",
      "acts",
      "make",
      "fine",
      "walk",
      "house",
      "dress",
      "do",
      "write",
      "like",
      "which",
      "wrote",
      "those",
      "words",
      "done",
      "wrong",
      "soon",
      "they",
      "shown",
      "read",
      "book",
      "talk",
      "am",
      "being",
      "out",
      "his",
      "will",
      "look",
      "same",
      "sharp",
      "pains",
      "loss",
      "child",
      "long",
      "see",
      "can",
      "both",
      "most",
      "skill",
      "how",
      "friends",
      "are",
      "humbled",
      "them",
      "pass",
      "form",
      "all",
      "so",
      "more",
      "than",
      "time",
      "an",
      "life",
      "showed",
      "free",
      "could",
      "chide",
      "ought",
      "used",
      "thing",
      "word",
      "some",
      "fit",
      "us",
      "called",
      "say",
      "one",
      "no",
      "our",
      "whom",
      "live",
      "when",
      "friend",
      "finds",
      "fault",
      "if",
      "find",
      "try",
      "speak",
      "well",
      "it",
      "is",
      "love",
      "kin",
      "truth",
      "through",
      "dion",
      "there",
      "law",
      "rights",
      "loved",
      "need",
      "wished",
      "did",
      "wish",
      "quite",
      "plain",
      "just",
      "set",
      "thought",
      "spoke",
      "bad",
      "put",
      "doing",
      "laugh",
      "hand",
      "right",
      "think",
      "art",
      "due",
      "men",
      "call",
      "weal",
      "boys",
      "sup",
      "went",
      "failed",
      "found",
      "stopped",
      "keep",
      "tired",
      "yet",
      "check",
      "were",
      "blame",
      "got",
      "gods",
      "court",
      "gifts",
      "trying",
      "please",
      "mean",
      "slave",
      "ripe",
      "mens",
      "this",
      "true",
      "made",
      "took",
      "care",
      "health",
      "stood",
      "gave",
      "help",
      "each",
      "might",
      "acted",
      "fond",
      "change",
      "stay",
      "came",
      "fresh",
      "few",
      "rare",
      "these",
      "looked",
      "mans",
      "take",
      "bath",
      "hours",
      "ate",
      "coast",
      "asked",
      "harsh",
      "may",
      "point",
      "weak",
      "strong",
      "bear",
      "mark",
      "has",
      "soul",
      "owe",
      "though",
      "their",
      "trial",
      "brought",
      "proof",
      "pride",
      "bring",
      "guards",
      "show",
      "near",
      "must",
      "thank",
      "rouse",
      "pleased",
      "seen",
      "haste",
      "place",
      "seemed",
      "hope",
      "still",
      "young",
      "knew",
      "clear",
      "fall",
      "short",
      "held",
      "touched",
      "cured",
      "fate",
      "die",
      "spent",
      "last",
      "years",
      "her",
      "told",
      "means",
      "wife",
      "dreams",
      "waste",
      "shall",
      "meet",
      "blood",
      "seed",
      "fix",
      "hate",
      "feet",
      "rows",
      "teeth",
      "act",
      "vexed",
      "turn",
      "flesh",
      "breath",
      "part",
      "throw",
      "thy",
      "books",
      "thou",
      "wast",
      "now",
      "dying",
      "veins",
      "air",
      "sent",
      "sucked",
      "third",
      "thus",
      "old",
      "let",
      "pulled",
      "strings",
      "lot",
      "shrink",
      "full",
      "thence",
      "flow",
      "brings",
      "thee",
      "fixed",
      "cast",
      "thirst",
      "heart",
      "hast",
      "dost",
      "use",
      "clouds",
      "mind",
      "go",
      "wilt",
      "doest",
      "seest",
      "lays",
      "hold",
      "flows",
      "thine",
      "souls",
      "learn",
      "new",
      "cease",
      "whirled",
      "minds",
      "acts-",
      "would",
      "says",
      "seems",
      "pain",
      "wronged",
      "moved",
      "since",
      "real",
      "rest",
      "worse",
      "guard",
      "great",
      "death",
      "bait",
      "noised",
      "fame",
      "dead",
      "whose",
      "fact",
      "looks",
      "parts",
      "wretched",
      "round",
      "pries",
      "earth",
      "poet",
      "seeks",
      "pure",
      "dear",
      "move",
      "less",
      "white",
      "black",
      "shouldst",
      "going",
      "three",
      "ten",
      "lost",
      "mere",
      "lose",
      "past",
      "two",
      "forms",
      "come",
      "hundred",
      "next",
      "turns",
      "plays",
      "its",
      "aim",
      "end",
      "flux",
      "dull",
      "whirl",
      "hard",
      "stream",
      "dream",
      "whence",
      "harm",
      "why",
    ],
     [
      "verus",
      "morals",
      "temper",
      "father",
      "manly",
      "mother",
      "piety",
      "only",
      "evil",
      "even",
      "further",
      "living",
      "removed",
      "habits",
      "frequented",
      "public",
      "teachers",
      "neither",
      "party",
      "games",
      "circus",
      "either",
      "labour",
      "little",
      "meddle",
      "other",
      "peoples",
      "affairs",
      "ready",
      "listen",
      "slander",
      "busy",
      "myself",
      "about",
      "trifling",
      "credit",
      "jugglers",
      "driving",
      "away",
      "daemons",
      "quails",
      "fighting",
      "endure",
      "freedom",
      "become",
      "hearer",
      "bacchius",
      "written",
      "desired",
      "belongs",
      "grecian",
      "received",
      "required",
      "astray",
      "writing",
      "matters",
      "showing",
      "order",
      "display",
      "abstain",
      "poetry",
      "outdoor",
      "letters",
      "letter",
      "respect",
      "offended",
      "disposed",
      "assent",
      "indebted",
      "purpose",
      "nothing",
      "moment",
      "except",
      "reason",
      "always",
      "illness",
      "clearly",
      "yielding",
      "peevish",
      "giving",
      "before",
      "eyes",
      "smallest",
      "merits",
      "receive",
      "esteemed",
      "favours",
      "without",
      "letting",
      "sextus",
      "governed",
      "manner",
      "idea",
      "nature",
      "after",
      "persons",
      "power",
      "himself",
      "any",
      "highly",
      "never",
      "anger",
      "passion",
      "also",
      "express",
      "noisy",
      "possessed",
      "knowledge",
      "refrain",
      "uttered",
      "very",
      "answer",
      "joining",
      "itself",
      "fronto",
      "observe",
      "envy",
      "tyrant",
      "among",
      "rather",
      "leisure",
      "excuse",
      "neglect",
      "duties",
      "urgent",
      "restore",
      "usual",
      "reported",
      "children",
      "truly",
      "brother",
      "justice",
      "thrasea",
      "cato",
      "brutus",
      "regard",
      "equal",
      "kingly",
      "respects",
      "others",
      "cherish",
      "hopes",
      "believe",
      "observed",
      "condemned",
      "aside",
      "moral",
      "sweetness",
      "believed",
      "surprise",
      "hurry",
      "perplexed",
      "dejected",
      "ever",
      "disguise",
      "forgive",
      "presented",
      "diverted",
      "improved",
      "despised",
      "venture",
      "better",
      "mildness",
      "honours",
      "propose",
      "common",
      "firmness",
      "deserts",
      "derived",
      "action",
      "released",
      "attend",
      "abroad",
      "habit",
      "present",
      "cheerful",
      "provide",
      "applause",
      "watchful",
      "over",
      "empire",
      "conduct",
      "conduce",
      "fortune",
      "gives",
      "supply",
      "enjoyed",
      "sophist",
      "home-bred",
      "flippant",
      "pedant",
      "perfect",
      "above",
      "able",
      "manage",
      "honoured",
      "reproach",
      "pretended",
      "easy",
      "bodys",
      "greatly",
      "attached",
      "seldom",
      "enjoy",
      "country",
      "places",
      "employ",
      "headache",
      "secrets",
      "prudence",
      "buildings",
      "people",
      "building",
      "houses",
      "texture",
      "colour",
      "clothes",
      "slaves",
      "lorium",
      "villa",
      "behaved",
      "pardon",
      "violent",
      "carried",
      "sweating",
      "applied",
      "recorded",
      "many",
      "cannot",
      "excess",
      "enough",
      "sober",
      "having",
      "parents",
      "sister",
      "kinsmen",
      "nearly",
      "hurried",
      "into",
      "offence",
      "against",
      "offered",
      "favour",
      "thankful",
      "longer",
      "preserved",
      "flower",
      "proper",
      "season",
      "deferred",
      "subjected",
      "ruler",
      "palace",
      "wanting",
      "dresses",
      "torches",
      "statues",
      "such-like",
      "fashion",
      "private",
      "person",
      "meaner",
      "remiss",
      "befits",
      "stupid",
      "deformed",
      "body",
      "studies",
      "perhaps",
      "engaged",
      "making",
      "progress",
      "station",
      "honour",
      "desire",
      "putting",
      "because",
      "frequent",
      "depended",
      "hindered",
      "forthwith",
      "almost",
      "direct",
      "fallen",
      "passions",
      "often",
      "humour",
      "repent",
      "mothers",
      "happened",
      "simple",
      "masters",
      "writers",
      "heavens",
      "require",
      "quadi",
      "granua",
      "\nbegin",
      "morning",
      "saying",
      "thyself",
      "happen",
      "ugly",
      "akin",
      "portion",
      "injured",
      "angry",
      "kinsman",
      "upper",
      "lower",
      "acting",
      "ruling",
      "distract",
      "allowed",
      "despise",
      "bones",
      "network",
      "nerves",
      "again",
      "puppet",
      "future",
      "ordered",
      "whole",
      "serves",
      "maintain",
      "changes",
      "compounded",
      "mayest",
      "perceive",
      "efflux",
      "limit",
      "clearing",
      "return",
      "roman",
      "feeling",
      "relief",
      "laying",
      "commands",
      "self-love",
      "given",
      "quiet",
      "finished",
      "upon",
      "around",
      "avoid",
      "triflers",
      "wearied",
      "object",
      "related",
      "hinders",
      "notions",
      "mankind-",
      "committed",
      "excited",
      "certain",
      "offends",
      "pleasure",
      "rightly",
      "worthy",
      "compelled",
      "impulse",
      "towards",
      "depart",
      "afraid",
      "involve",
      "indeed",
      "exist",
      "concern",
      "human",
      "devoid",
      "evils",
      "provided",
      "correct",
      "mistake",
      "quickly",
      "bodies",
      "attract",
      "worthless",
      "sordid",
      "are-",
      "voices",
      "comes",
      "deity",
      "beneath",
      "neighbours",
      "daemon",
      "within",
      "consists",
      "keeping",
      "merit",
      "kinship",
      "pity",
      "defect",
      "thousand",
      "times",
      "loses",
      "lives",
      "longest",
      "shortest",
      "appears",
      "circle",
      "makes",
      "whether",
      "during",
      "second",
      "liver",
      "soonest",
      "deprived",
      "cynic",
      "violence",
      "abscess",
      "tumour",
      "happens",
      "contained",
      "moves",
      "fourthly",
      "fifthly",
      "allows",
      "follow",
      "ancient",
      "city",
      "substance",
      "subject",
      "divine",
      "vapour",
      "warfare",
      "strangers",
      "sojourn",
      "unharmed",
      "allotted",
      "coming",
      "waiting",
      "changing",
    ],[
      "grandfather",
      "government",
      "remembrance",
      "modesty",
      "character",
      "abstinence",
      "governor",
      "partizan",
      "scutarius",
      "gladiators",
      "endurance",
      "diognetus",
      "intimate",
      "tandasis",
      "marcianus",
      "dialogues",
      "whatever",
      "discipline",
      "rusticus",
      "impression",
      "sophistic",
      "orations",
      "practises",
      "rhetoric",
      "sinuessa",
      "easily",
      "pacified",
      "reconciled",
      "readiness",
      "satisfied",
      "hastily",
      "overmuch",
      "acquainted",
      "discourses",
      "collection",
      "steadiness",
      "occasion",
      "example",
      "resolute",
      "instruction",
      "considered",
      "experience",
      "expounding",
      "principles",
      "unnoticed",
      "family",
      "fatherly",
      "gravity",
      "interests",
      "tolerate",
      "ignorant",
      "opinions",
      "readily",
      "intercourse",
      "flattery",
      "venerated",
      "associated",
      "faculty",
      "ordering",
      "grammarian",
      "fault-finding",
      "reproachful",
      "barbarous",
      "expression",
      "introduce",
      "inquiry",
      "suggestion",
      "patricians",
      "deficient",
      "paternal",
      "affection",
      "platonic",
      "frequently",
      "relation",
      "alleging",
      "catulus",
      "domitius",
      "severus",
      "helvidius",
      "polity",
      "concealment",
      "conjecture",
      "maximus",
      "anything",
      "cheerfulness",
      "admixture",
      "dignity",
      "complaining",
      "intention",
      "vexation",
      "passionate",
      "accustomed",
      "falsehood",
      "appearance",
      "humorous",
      "determined",
      "vainglory",
      "every",
      "according",
      "occasions",
      "vigorous",
      "remission",
      "overcome",
      "citizen",
      "careful",
      "themselves",
      "foresee",
      "popular",
      "manager",
      "patiently",
      "flattering",
      "populace",
      "sobriety",
      "novelty",
      "abundant",
      "arrogance",
      "excusing",
      "acknowledged",
      "besides",
      "offensive",
      "personal",
      "careless",
      "attention",
      "physicians",
      "medicine",
      "external",
      "eloquence",
      "unsteady",
      "paroxysms",
      "spectacles",
      "construction",
      "donations",
      "curious",
      "beauty",
      "lanuvium",
      "tusculum",
      "examined",
      "abundance",
      "confusion",
      "orderly",
      "socrates",
      "grandfathers",
      "something",
      "concurrence",
      "concubine",
      "possible",
      "embroidered",
      "interest",
      "vigilance",
      "impressions",
      "observing",
      "instructions",
      "theodotus",
      "whenever",
      "another",
      "obedient",
      "remedies",
      "bloodspitting",
      "giddiness...",
      "histories",
      "syllogisms",
      "occupy",
      "arrogant",
      "deceitful",
      "envious",
      "unsocial",
      "ignorance",
      "eyelids",
      "contrary",
      "contexture",
      "arteries",
      "consider",
      "movements",
      "providence",
      "separated",
      "advantage",
      "universe",
      "elements",
      "murmuring",
      "cheerfully",
      "remember",
      "existence",
      "steadily",
      "aversion",
      "discontent",
      "observes",
      "honouring",
      "sufficient",
      "movement",
      "unhappy",
      "theophrastus",
      "accordance",
      "offences",
      "blameable",
      "contraction",
      "womanish",
      "regulate",
      "enable",
      "overlooked",
      "certainly",
      "dishonour",
      "equally",
      "therefore",
      "disappear",
      "sensible",
      "terrify",
      "vapoury",
      "abstractive",
      "reflection",
      "resolves",
      "however",
      "conduces",
      "purposes",
      "traverses",
      "perceiving",
      "reverence",
      "thoughtlessness",
      "excellence",
      "deprives",
      "perishes",
      "difference",
      "infinite",
      "opinion",
      "monimus",
      "manifest",
      "receives",
      "becomes",
      "ourselves",
      "natures",
      "injuring",
      "untruly",
      "thoughtlessly",
      "reference",
      "rational",
      "animals",
      "perception",
      "judgement",
      "after-fame",
      "oblivion",
      "superior",
      "pleasures",
      "falsely",
      "accepting",
      "wherever",
      "finally",
      "carnuntum",
    ],
     [
      "reputation",
      "beneficence",
      "simplicity",
      "great-grandfather",
      "liberally",
      "parmularius",
      "incantations",
      "philosophy",
      "improvement",
      "emulation",
      "speculative",
      "delivering",
      "hortatory",
      "benevolent",
      "carefully",
      "superficial",
      "understanding",
      "epictetus",
      "communicated",
      "apollonius",
      "undeviating",
      "disposition",
      "conformably",
      "affectation",
      "agreeable",
      "discovering",
      "intelligent",
      "methodical",
      "necessary",
      "entirely",
      "affectionate",
      "approbation",
      "ostentation",
      "alexander",
      "solecistic",
      "strange-sounding",
      "dexterously",
      "confirmation",
      "duplicity",
      "hypocrisy",
      "generally",
      "necessity",
      "continually",
      "occupations",
      "indifferent",
      "administered",
      "consistency",
      "self-government",
      "circumstances",
      "amazement",
      "suspicious",
      "unchangeable",
      "resolution",
      "perseverance",
      "obligation",
      "accompany",
      "persistency",
      "appearances",
      "extravagant",
      "expenditure",
      "commodity",
      "philosophers",
      "conversation",
      "reasonable",
      "applications",
      "particular",
      "institutions",
      "economy",
      "exhibition",
      "toll-collector",
      "behaviour",
      "implacable",
      "severally",
      "vigorously",
      "consistently",
      "invincible",
      "associates",
      "everything",
      "virility",
      "proficiency",
      "completely",
      "inspirations",
      "admonitions",
      "benedicta",
      "amatory",
      "inclination",
      "busy-body",
      "ungrateful",
      "beautiful",
      "intelligence",
      "divinity",
      "dissatisfied",
      "interweaving",
      "involution",
      "carelessness",
      "reverences",
      "felicity",
      "activity",
      "comparison",
      "philosopher",
      "unconscious",
      "overpowered",
      "intemperate",
      "accordingly",
      "altogether",
      "contemptible",
      "perishable",
      "intellectual",
      "operation",
      "sincerely",
      "veneration",
      "sometimes",
      "distinguishing",
      "eternity",
      "separation",
      "considering",
      "composition",
      "putrefaction",
      "dissolution",
      "apprehension",
    ],
     [
      "miracle-workers",
      "passionately",
      "philosophical",
      "consideration",
      "accommodating",
      "athenodotus",
      "everybody",
      "deliberation",
      "investigation",
      "immediately",
      "administration",
      "superstitious",
      "unseasonable",
      "opportunity",
      "participates",
      "co-operation",
      "administrator",
      "particularly",
      "imagination",
      "dissatisfaction",
      "insincerely",
    ],
  ]
  
